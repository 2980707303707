import React from 'react';
import { useLocation } from 'react-router-dom';

function Success() {
    const location = useLocation();
    const query = new URLSearchParams(location.search);
    const message = query.get('message');

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-3xl font-bold text-center mb-10">Success</h1>
            <p className="text-lg text-center">{message || 'Operation completed successfully!'}</p>
        </div>
    );
}

export default Success;
