import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import ProductEditModal from './ProductEditModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { quantum } from 'ldrs'; // uiball yükleme animasyonu
quantum.register();

function AllProducts() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [filter, setFilter] = useState('all');
  const [generatedData, setGeneratedData] = useState([]);
  const [error, setError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(10);
  const [sortOrder, setSortOrder] = useState('desc');
  const [showModal, setShowModal] = useState(false);
  const [editingProductId, setEditingProductId] = useState(null);
  const [loading, setLoading] = useState(false); // Loader için state

  const apiKey = process.env.REACT_APP_OPENAI;

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true); // Ürünleri yüklerken loader'ı göster
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get('https://etsyhelper.onrender.com/products', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(response.data);
      } catch (error) {
        console.error('Error fetching products:', error);
      } finally {
        setLoading(false); // Yükleme tamamlandığında loader'ı gizle
      }
    };
    fetchProducts();
  }, [user]);

  const toggleSelectProduct = (productId) => {
    if (selectedProducts.includes(productId)) {
      setSelectedProducts(selectedProducts.filter(id => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId]);
    }
  };

  const selectAllProducts = () => {
    if (selectedProducts.length === products.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(products.map(product => product.id));
    }
  };

  const generateProductData = async () => {
    try {
        setLoading(true); // Ürün verisi oluşturulurken loader'ı göster
        const token = localStorage.getItem('token');

        // Önce ürün isimlerini oluştur
        const selectedProductData = selectedProducts.map(productId => {
            const product = products.find(p => p.id === productId);

            // `base_image`in boş olup olmadığını kontrol edin
            if (!product.base_image) {
                throw new Error(`Base image URL is missing for product ID: ${productId}`);
            }

            return {
                id: productId,
                image_url: product.base_image // `base_image` burada `image_url` olarak kullanılacak
            };
        });

        // İlk adım: Ürün isimlerini oluşturun
        const nameResponse = await axios.post('https://etsyhelper.onrender.com/generate', {
            apiKey,
            products: selectedProductData
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        if (nameResponse.data.error) {
            throw new Error(nameResponse.data.error);
        }

        // İkinci adım: Description oluşturma, burada `nameResponse`dan gelen `title` kullanılıyor
        const descriptionResponse = await axios.post('https://etsyhelper.onrender.com/generate-product-description', {
            products: nameResponse.data.map(product => ({
                product_id: product.product_id,
                name: product.title // `title` değerini kullanıyoruz
            }))
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Üçüncü adım: Tags oluşturma, burada `nameResponse`dan gelen `title` kullanılıyor
        const tagsResponse = await axios.post('https://etsyhelper.onrender.com/generate-product-tags', {
            products: nameResponse.data.map(product => ({
                product_id: product.product_id,
                name: product.title // `title` değerini kullanıyoruz
            }))
        }, {
            headers: { Authorization: `Bearer ${token}` },
        });

        // Ürünleri güncelleyin
        const updatedProducts = products.map(product => {
            const updatedProduct = nameResponse.data.find(p => p.product_id === product.id);
            const descriptionProduct = descriptionResponse.data.find(p => p.product_id === product.id);
            const tagsProduct = tagsResponse.data.find(p => p.product_id === product.id);

            return updatedProduct ? {
                ...product,
                name: updatedProduct.title, // İsmi güncelleyin
                description: descriptionProduct ? descriptionProduct.description : product.description, // Açıklamayı güncelleyin
                tags: tagsProduct ? tagsProduct.tags : product.tags // Etiketleri güncelleyin
            } : product;
        });

        setProducts(updatedProducts); // products state'ini güncelleyin
        setSelectedProducts([]); // Seçili ürünleri temizle
        toast.success('Product name, description, and tags generated successfully!');
    } catch (error) {
        console.error('Error generating product data:', error);
        setError(error.message);
        toast.error('Failed to generate product data.');
    } finally {
        setLoading(false); // Yükleme tamamlandığında loader'ı gizle
    }
};



  const deleteProduct = async (productId) => {
    try {
      setLoading(true); // Silme işlemi başlamadan önce loader'ı göster
      const token = localStorage.getItem('token');
      await axios.delete(`https://etsyhelper.onrender.com/products/${productId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setProducts(products.filter(product => product.id !== productId));
      toast.success('Product deleted successfully!');
    } catch (error) {
      console.error('Error deleting product:', error);
      setError('Failed to delete product');
      toast.error('Failed to delete product.');
    } finally {
      setLoading(false); // Silme işlemi tamamlandığında loader'ı gizle
    }
  };

  const openEditModal = (productId) => {
    setEditingProductId(productId);
    setShowModal(true);
  };

  const handleProductUpdate = () => {
    setShowModal(false);
    const fetchProducts = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get('https://etsyhelper.onrender.com/products', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setProducts(response.data);
        toast.success('Product updated successfully!');
      } catch (error) {
        console.error('Error fetching products:', error);
        toast.error('Failed to update product.');
      }
    };
    fetchProducts();
  };

  const filteredProducts = products.filter(product => {
    if (filter === 'preparing') return !product.name || !product.description || !product.tags;
    if (filter === 'ready') return product.name && product.description && product.tags;
    return true;
  });

  const sortedProducts = filteredProducts.sort((a, b) => {
    if (sortOrder === 'desc') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else {
      return new Date(a.created_at) - new Date(b.created_at);
    }
  });

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = sortedProducts.slice(indexOfFirstProduct, indexOfLastProduct);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(sortedProducts.length / productsPerPage);

  return (
    <div className="container mx-auto mt-10">
      <h1 className="text-3xl font-bold text-center mb-10">All Products</h1>
      <div className="mb-4 flex justify-between items-center">
        <button onClick={selectAllProducts} className="bg-blue-500 text-white px-4 py-2 rounded">
          {selectedProducts.length === products.length ? 'Deselect All' : 'Select All'}
        </button>
        <button onClick={generateProductData} className="bg-green-500 text-white px-4 py-2 rounded">
          Generate Data
        </button>
        <div>
          <button onClick={() => setFilter('all')} className={`mx-2 px-4 py-2 rounded ${filter === 'all' ? 'bg-gray-700 text-white' : 'bg-gray-300'}`}>
            All
          </button>
          <button onClick={() => setFilter('preparing')} className={`mx-2 px-4 py-2 rounded ${filter === 'preparing' ? 'bg-yellow-500 text-white' : 'bg-gray-300'}`}>
            Preparing
          </button>
          <button onClick={() => setFilter('ready')} className={`mx-2 px-4 py-2 rounded ${filter === 'ready' ? 'bg-green-500 text-white' : 'bg-gray-300'}`}>
            Ready
          </button>
        </div>
        <div>
          <button onClick={() => setSortOrder('desc')} className={`mx-2 px-4 py-2 rounded ${sortOrder === 'desc' ? 'bg-gray-700 text-white' : 'bg-gray-300'}`}>
            Latest
          </button>
          <button onClick={() => setSortOrder('asc')} className={`mx-2 px-4 py-2 rounded ${sortOrder === 'asc' ? 'bg-gray-700 text-white' : 'bg-gray-300'}`}>
            Oldest
          </button>
        </div>
      </div>
      {loading && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <l-quantum size= "45" speed= "1.75" color="black" ></l-quantum>
        </div>
      )}
      <ul className="list-disc pl-5">
        {currentProducts.map((product, index) => (
          <li key={index} className={`bg-white p-4 rounded-lg shadow-md mb-4 flex items-center ${product.name && product.description && product.tags ? 'border-l-4 border-green-500' : ''}`}>
            <input
              type="checkbox"
              checked={selectedProducts.includes(product.id)}
              onChange={() => toggleSelectProduct(product.id)}
              className="mr-4"
            />
            <img src={product.base_image} alt={`product-${index}`} className="w-16 h-16 object-contain rounded-lg mr-4" />
            <div className="flex-grow">
              <p className="text-sm font-semibold">{product.name}</p>
              <p className="text-xs text-gray-500">
                {product.description && product.description.length > 100 
                  ? `${product.description.substring(0, 200)}...`
                  : product.description || 'No description available'} 
              </p>
              <div className="flex flex-wrap mt-2">
                {product.tags ? product.tags.split(',').map((tag, index) => (
                  <span key={index} className="bg-green-100 text-green-700 text-xs font-semibold mr-2 mb-2 px-2 py-1 rounded">
                    {tag.trim()}
                  </span>
                )): <span className="text-gray-500 text-xs">No tags available</span>}
              </div>
            </div>
            <p className="text-sm font-semibold mr-4 bg-gray-100 text-gray-700 p-2 rounded">{product.variation_profile}</p> {/* Varyasyon profili sola dayalı ve arka plan rengi */}
            <span className={`ml-4 px-2 py-1 text-xs font-semibold ${product.name && product.description && product.tags ? 'bg-green-100 text-green-700' : 'bg-yellow-100 text-yellow-700'}`}>
              {product.name && product.description && product.tags ? 'Ready' : 'Preparing'}
            </span>
            <button onClick={() => openEditModal(product.id)} className="bg-yellow-500 text-white px-4 py-2 rounded ml-4">
              Edit
            </button>
            <button onClick={() => deleteProduct(product.id)} className="bg-red-500 text-white px-4 py-2 rounded ml-4">
              Delete
            </button>
          </li>
        ))}
      </ul>
      <div className="flex justify-center mt-4">
        <button
          onClick={() => paginate(currentPage - 1)}
          className={`mx-2 px-4 py-2 rounded ${currentPage === 1 ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => paginate(index + 1)}
            className={`mx-2 px-4 py-2 rounded ${currentPage === index + 1 ? 'bg-gray-700 text-white' : 'bg-gray-300'}`}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => paginate(currentPage + 1)}
          className={`mx-2 px-4 py-2 rounded ${currentPage === totalPages ? 'bg-gray-300' : 'bg-blue-500 text-white'}`}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      
      {error && (
        <div className="mt-10 text-red-500">
          <p><strong>Error:</strong> {error}</p>
        </div>
      )}
      <ProductEditModal
        productId={editingProductId}
        showModal={showModal}
        setShowModal={setShowModal}
        onProductUpdate={handleProductUpdate}
      />
      <ToastContainer />
    </div>
  );
}

export default AllProducts;
