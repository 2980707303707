import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';

const OAuthCallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const handleOAuthCallback = async () => {
            const query = new URLSearchParams(window.location.search);
            const code = query.get('code');
            const returnedState = query.get('state');
            const originalState = localStorage.getItem('oauthState');

            if (!code) {
                toast.error('Etsy yetkilendirmesi sırasında hata oluştu.');
                return;
            }

            if (returnedState !== originalState) {
                toast.error('State doğrulaması başarısız.');
                return;
            }

            try {
                // Token alma işlemi
                const response = await axios.post('https://etsyhelper.onrender.com/etsy/token', { code });
                const { access_token, refresh_token } = response.data;

                // Token'ları saklama
                localStorage.setItem('etsyAccessToken', access_token);
                localStorage.setItem('etsyRefreshToken', refresh_token);

                toast.success('Yetkilendirme başarılı!');
                navigate('/my-account/stores'); // Yetkilendirme sonrası yönlendirme

            } catch (error) {
                console.error('Token alma hatası:', error);
                toast.error('Erişim tokeni alınamadı.');
            }
        };

        handleOAuthCallback();
    }, [navigate]);

    return (
        <div className="container mx-auto mt-10">
            <h1 className="text-3xl font-bold text-center">Etsy Yetkilendirmesi Yapılıyor...</h1>
        </div>
    );
};

export default OAuthCallback;
