import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'; // Toast stilini dahil edin
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const DescriptionGenerator = () => {
  const { user } = useAuth();
  const [firstParagraph, setFirstParagraph] = useState('Bu bölüm ürün görseline göre otomatik olarak AI tarafından doldurulacaktır.');
  const [secondParagraph, setSecondParagraph] = useState('');
  const [thirdParagraph, setThirdParagraph] = useState('');
  const [previewDescription, setPreviewDescription] = useState('');

  useEffect(() => {
    const fetchTemplate = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get('https://etsyhelper.onrender.com/store/template', {
          headers: { Authorization: `Bearer ${token}` },
        });

        setSecondParagraph(response.data.secondParagraph || '');
        setThirdParagraph(response.data.thirdParagraph || '');
        setPreviewDescription(`Bu bölüm ürün görseline göre otomatik olarak AI tarafından doldurulacaktır.\n\n${response.data.secondParagraph || ''}\n\n${response.data.thirdParagraph || ''}`);
      } catch (error) {
        console.error('Error fetching template:', error);
      }
    };

    fetchTemplate();
  }, [user]);

  const handleSaveTemplate = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post('https://etsyhelper.onrender.com/store/template', {
        secondParagraph,
        thirdParagraph,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      toast.success('Şablon başarıyla kaydedildi!');
    } catch (error) {
      console.error('Error saving template:', error);
      toast.error('Failed to save template.');
    }
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="container mx-auto mt-10">
        <h1 className="text-3xl font-bold text-center mb-6">Description Generator</h1>
        <div className="grid grid-cols-2 gap-8">
          <div>
            <div className="mb-6">
              <h2 className="text-lg font-semibold">Birinci Paragraf</h2>
              <div className="p-4 bg-blue-50 border border-blue-200 rounded-lg">
                <p className="text-gray-700">Bu bölüm ürün görseline göre otomatik olarak AI tarafından doldurulacaktır.</p>
              </div>
            </div>

            <div className="mb-6">
              <h2 className="text-lg font-semibold">İkinci Paragraf (Ürün Kalitesi)</h2>
              <textarea
                value={secondParagraph}
                onChange={(e) => {
                  setSecondParagraph(e.target.value);
                  setPreviewDescription(`Bu bölüm ürün görseline göre otomatik olarak AI tarafından doldurulacaktır.\n\n${e.target.value}\n\n${thirdParagraph}`);
                }}
                rows="5"
                className="w-full p-4 border rounded"
                placeholder="Enter the second paragraph template..."
              />
            </div>

            <div className="mb-6">
              <h2 className="text-lg font-semibold">Üçüncü Paragraf (Kargolama & Paketleme)</h2>
              <textarea
                value={thirdParagraph}
                onChange={(e) => {
                  setThirdParagraph(e.target.value);
                  setPreviewDescription(`Bu bölüm ürün görseline göre otomatik olarak AI tarafından doldurulacaktır.\n\n${secondParagraph}\n\n${e.target.value}`);
                }}
                rows="5"
                className="w-full p-4 border rounded"
                placeholder="Enter the third paragraph template..."
              />
            </div>

            <button
              onClick={handleSaveTemplate}
              className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
            >
              Şablonu Kaydet
            </button>
          </div>

          <div className="bg-gray-100 p-6 rounded-lg shadow-md">
            <h2 className="text-lg font-semibold mb-4">Preview</h2>
            <p className="whitespace-pre-wrap">{previewDescription}</p>
          </div>
        </div>
        <ToastContainer />
      </div>
    </DndProvider>
  );
};

export default DescriptionGenerator;
