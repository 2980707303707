import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { toast } from 'react-toastify';

function StaticImageUpload({ profileId, storeId, fetchStaticImages }) {
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);

  const onDrop = (acceptedFiles) => {
    setFiles([...files, ...acceptedFiles]);
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: 'image/*'
  });

  const handleUpload = async () => {
    if (files.length === 0) {
      toast.error('Lütfen resim seçin.');
      return;
    }

    const formData = new FormData();
    formData.append('profile_id', profileId);  // Profil ID'sini ekle
    formData.append('store_id', storeId);  // Mağaza ID'sini ekle
    files.forEach((file) => {
      formData.append('static_images', file);  // Resim dosyalarını ekle
    });

    try {
      setUploading(true);
      const token = localStorage.getItem('token');
      const response = await axios.post('https://etsyhelper.onrender.com/static-images', formData, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 201) {
        toast.success('Resimler başarıyla yüklendi!');
        fetchStaticImages();  // Resimleri yeniden yükleme işlemi
        setFiles([]);  // Dosya listesini sıfırla
      } else {
        toast.error('Resim yüklenirken bir hata oluştu.');
      }
    } catch (error) {
      console.error('Resim yükleme hatası:', error);
      toast.error('Yükleme sırasında bir hata oluştu.');
    } finally {
      setUploading(false);
    }
  };

  return (
    <div className="container mx-auto">
      <div {...getRootProps()} className="border-dashed border-2 p-6 text-center cursor-pointer">
        <input {...getInputProps()} />
        <p>Resimleri buraya sürükleyin veya tıklayın</p>
      </div>

      <div className="mt-4 flex flex-wrap">
        {files.map((file, idx) => (
          <div key={idx} className="w-32 h-32 m-2">
            <img src={URL.createObjectURL(file)} alt="preview" className="object-cover w-full h-full rounded-lg" />
          </div>
        ))}
      </div>

      <button
        onClick={handleUpload}
        className="mt-4 bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600"
        disabled={uploading}
      >
        {uploading ? 'Yükleniyor...' : 'Resimleri Yükle'}
      </button>
    </div>
  );
}

export default StaticImageUpload;
