import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import VariationTab from '../components/VariationTab';
import PricesTab from '../components/PricesTab';
import ImagesTab from '../components/ImagesTab';

function VariationProfiles() {
  const { user } = useAuth();
  const [profiles, setProfiles] = useState([]);
  const [status, setStatus] = useState([]);
  const [expandedProfileId, setExpandedProfileId] = useState(null);
  const [activeTab, setActiveTab] = useState('variations');
  const [dimensionInput, setDimensionInput] = useState('');
  const [frameInput, setFrameInput] = useState('');
  const [priceInput, setPriceInput] = useState({});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  useEffect(() => {
    const fetchProfiles = async () => {
      try {
        const response = await axios.get('https://etsyhelper.onrender.com/variation_profiles');
        setProfiles(response.data);
      } catch (error) {
        console.error('Error fetching profiles:', error);
        setError('Failed to fetch profiles');
      }
    };

    const fetchStatus = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }
        const response = await axios.get('https://etsyhelper.onrender.com/variation_status', {
          headers: { Authorization: `Bearer ${token}` },
        });
        setStatus(response.data);
      } catch (error) {
        console.error('Error fetching status:', error);
        setError('Failed to fetch status');
      }
    };

    fetchProfiles();
    fetchStatus();
  }, [user]);

  const activateProfile = async (profileId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
      await axios.post('https://etsyhelper.onrender.com/activate_profile', { profile_id: profileId }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setStatus([...status, { profile_id: profileId, status: 1 }]);
      toast.success('Profile activated successfully');
    } catch (error) {
      console.error('Error activating profile:', error);
      setError('Failed to activate profile');
    }
  };

  const toggleProfileDetails = (profileId) => {
    if (expandedProfileId === profileId) {
      setExpandedProfileId(null);
    } else {
      setExpandedProfileId(profileId);
    }
  };

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const handleAddDimension = (profileId) => {
    if (dimensionInput) {
      const updatedProfiles = profiles.map(profile => {
        if (profile.id === profileId) {
          return { ...profile, dimensions: [...(profile.dimensions || []), { id: Date.now(), dimension: dimensionInput }] };
        }
        return profile;
      });
      setProfiles(updatedProfiles);
      setDimensionInput('');
      setSuccess('Dimension added successfully');
    } else {
      setError('Dimension input is required');
    }
  };

  const handleAddFrameOption = (profileId) => {
    if (frameInput) {
      const updatedProfiles = profiles.map(profile => {
        if (profile.id === profileId) {
          return { ...profile, frames: [...(profile.frames || []), { id: Date.now(), frame: frameInput }] };
        }
        return profile;
      });
      setProfiles(updatedProfiles);
      setFrameInput('');
      setSuccess('Frame option added successfully');
    } else {
      setError('Frame input is required');
    }
  };

  const handlePriceChange = (dimensionId, frameId, value) => {
    setPriceInput({ ...priceInput, [`${dimensionId}-${frameId}`]: value });
  };

  const handleSavePrices = (profileId) => {
    const pricesToSave = Object.entries(priceInput).map(([key, price]) => {
      const [dimensionId, frameId] = key.split('-');
      return { id: Date.now(), profileId, dimensionId, frameId, price };
    });

    const updatedProfiles = profiles.map(profile => {
      if (profile.id === profileId) {
        return { ...profile, prices: [...(profile.prices || []), ...pricesToSave] };
      }
      return profile;
    });

    setProfiles(updatedProfiles);
    setPriceInput({});
    setSuccess('Prices saved successfully');
  };

  const handleEditDimension = (profileId, dimensionId, newValue) => {
    const updatedProfiles = profiles.map(profile => {
      if (profile.id === profileId) {
        return {
          ...profile,
          dimensions: profile.dimensions.map(d => d.id === dimensionId ? { ...d, dimension: newValue } : d)
        };
      }
      return profile;
    });
    setProfiles(updatedProfiles);
  };

  const handleDeleteDimension = (profileId, dimensionId) => {
    const updatedProfiles = profiles.map(profile => {
      if (profile.id === profileId) {
        return { ...profile, dimensions: profile.dimensions.filter(d => d.id !== dimensionId) };
      }
      return profile;
    });
    setProfiles(updatedProfiles);
    setSuccess('Dimension deleted successfully');
  };

  const handleEditFrameOption = (profileId, frameId, newValue) => {
    const updatedProfiles = profiles.map(profile => {
      if (profile.id === profileId) {
        return {
          ...profile,
          frames: profile.frames.map(f => f.id === frameId ? { ...f, frame: newValue } : f)
        };
      }
      return profile;
    });
    setProfiles(updatedProfiles);
  };

  const handleDeleteFrameOption = (profileId, frameId) => {
    const updatedProfiles = profiles.map(profile => {
      if (profile.id === profileId) {
        return { ...profile, frames: profile.frames.filter(f => f.id !== frameId) };
      }
      return profile;
    });
    setProfiles(updatedProfiles);
    setSuccess('Frame option deleted successfully');
    toast.success('Frame option deleted successfully');
  };

  const handleSetAdded = () => {
    // Bu fonksiyon mockup set eklendiğinde çalışacak
    setSuccess('Mockup set added successfully');
    toast.success('Mockup set added successfully');
  };

  return (
    <div className="container mx-auto mt-10">
      <ToastContainer />
      <h1 className="text-4xl font-bold text-center mb-10">Variation Profiles</h1>
      {error && <p className="text-red-500 mb-4">{error}</p>}
      {success && <p className="text-green-500 mb-4">{success}</p>}

      <h2 className="text-2xl font-bold mb-4">Profiles</h2>
      <ul className="list-disc pl-5">
        {profiles.map((profile) => {
          const isActive = status.find(s => s.profile_id === profile.id && s.status === 1);
          return (
            <li key={profile.id} className="bg-white p-4 rounded-lg shadow-md mb-4">
              <div className="flex justify-between items-center">
                <div className="flex-grow cursor-pointer" onClick={() => toggleProfileDetails(profile.id)}>
                  <p className="text-lg font-semibold">{profile.name}</p>
                  <p className="text-sm font-semibold">{profile.width}:{profile.height}</p>
                </div>
                {!isActive ? (
                  <button
                    onClick={() => activateProfile(profile.id)}
                    className="bg-purple-500 text-white py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
                  >
                    Activate
                  </button>
                ) : (
                  <span className="text-green-500 font-semibold">Active</span>
                )}
                <span className="ml-4 cursor-pointer" onClick={() => toggleProfileDetails(profile.id)}>
                  {expandedProfileId === profile.id ? '-' : '+'}
                </span>
              </div>
              {expandedProfileId === profile.id && isActive && (
                <div className="mt-2">
                  <div className="border-b mb-4">
                    <button
                      onClick={() => handleTabClick('variations')}
                      className={`px-4 py-2 ${activeTab === 'variations' ? 'border-b-2 border-purple-500' : ''}`}
                    >
                      Variations
                    </button>
                    <button
                      onClick={() => handleTabClick('prices')}
                      className={`px-4 py-2 ${activeTab === 'prices' ? 'border-b-2 border-purple-500' : ''}`}
                    >
                      Prices
                    </button>
                    <button
                      onClick={() => handleTabClick('images')}
                      className={`px-4 py-2 ${activeTab === 'images' ? 'border-b-2 border-purple-500' : ''}`}
                    >
                      Images
                    </button>
                  </div>
                  {activeTab === 'variations' && (
                    <VariationTab
                      profile={profile}
                      handleEditDimension={handleEditDimension}
                      handleDeleteDimension={handleDeleteDimension}
                      handleAddDimension={handleAddDimension}
                      dimensionInput={dimensionInput}
                      setDimensionInput={setDimensionInput}
                      handleEditFrameOption={handleEditFrameOption}
                      handleDeleteFrameOption={handleDeleteFrameOption}
                      handleAddFrameOption={handleAddFrameOption}
                      frameInput={frameInput}
                      setFrameInput={setFrameInput}
                    />
                  )}
                  {activeTab === 'prices' && (
                    <PricesTab
                      profile={profile}
                      priceInput={priceInput}
                      handlePriceChange={handlePriceChange}
                      handleSavePrices={handleSavePrices}
                    />
                  )}
                  {activeTab === 'images' && (
                    <ImagesTab
                      profileId={profile.id}
                      onSetAdded={handleSetAdded}
                    />
                  )}
                </div>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default VariationProfiles;
