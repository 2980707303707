import React, { useState } from 'react';

function VariationTab({ profile, handleEditDimension, handleDeleteDimension, handleAddDimension, dimensionInput, setDimensionInput, handleEditFrameOption, handleDeleteFrameOption, handleAddFrameOption, frameInput, setFrameInput }) {
  return (
    <div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <h3 className="text-xl font-semibold">Dimensions</h3>
          <ul>
            {profile.dimensions?.map((dimension) => (
              <li key={dimension.id} className="mb-2 flex justify-between items-center">
                <input
                  type="text"
                  value={dimension.dimension}
                  onChange={(e) => handleEditDimension(profile.id, dimension.id, e.target.value)}
                  className="mr-2 flex-grow px-2 py-1 border border-gray-300 rounded-md"
                />
                <button
                  onClick={() => handleDeleteDimension(profile.id, dimension.id)}
                  className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
          <input
            type="text"
            value={dimensionInput}
            onChange={(e) => setDimensionInput(e.target.value)}
            placeholder="Add dimension"
            className="mt-2 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
          />
          <button
            onClick={() => handleAddDimension(profile.id)}
            className="mt-2 bg-purple-500 text-white py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
          >
            Add
          </button>
        </div>
        <div>
          <h3 className="text-xl font-semibold">Frame</h3>
          <ul>
            {profile.frames?.map((frame) => (
              <li key={frame.id} className="mb-2 flex justify-between items-center">
                <input
                  type="text"
                  value={frame.frame}
                  onChange={(e) => handleEditFrameOption(profile.id, frame.id, e.target.value)}
                  className="mr-2 flex-grow px-2 py-1 border border-gray-300 rounded-md"
                />
                <button
                  onClick={() => handleDeleteFrameOption(profile.id, frame.id)}
                  className="bg-red-500 text-white px-2 py-1 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
                >
                  Delete
                </button>
              </li>
            ))}
          </ul>
          <input
            type="text"
            value={frameInput}
            onChange={(e) => setFrameInput(e.target.value)}
            placeholder="Add frame option"
            className="mt-2 block w-full px-3 py-2 bg-gray-100 border border-gray-300 rounded-md focus:outline-none focus:ring focus:border-purple-500"
          />
          <button
            onClick={() => handleAddFrameOption(profile.id)}
            className="mt-2 bg-purple-500 text-white py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
          >
            Add
          </button>
        </div>
      </div>
    </div>
  );
}

export default VariationTab;
