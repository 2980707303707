import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function BulkUpload() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState(null);
  const [aspectRatios, setAspectRatios] = useState({});
  const [variationProfiles, setVariationProfiles] = useState({});

  const select_frame_room_and_offset = (aspect_ratio) => {
    if (0.90 <= aspect_ratio && aspect_ratio <= 1.10) {
      return 'Kare';
    } else if (4/7 * 0.90 <= aspect_ratio && aspect_ratio <= 4/7 * 1.10) {
      return 'Uzun Dikey';
    } else if (7/4 * 0.90 <= aspect_ratio && aspect_ratio <= 7/4 * 1.10) {
      return 'Uzun Yatay';
    } else if (1/2 * 0.90 <= aspect_ratio && aspect_ratio <= 1/2 * 1.10) {
      return 'aspect_1_2';
    } else if (2/1 * 0.90 <= aspect_ratio && aspect_ratio <= 2/1 * 1.10) {
      return 'aspect_2_1';
    } else if (aspect_ratio > 1) {
      return 'Yatay';
    } else {
      return 'Dikey';
    }
  };

  const onDrop = (acceptedFiles) => {
    if (files.length + acceptedFiles.length <= 20) {
      const updatedFiles = [...files, ...acceptedFiles];
      setFiles(updatedFiles);
      updatedFiles.forEach(file => {
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          setAspectRatios(prev => ({ ...prev, [file.name]: aspectRatio }));
          const variationProfile = select_frame_room_and_offset(aspectRatio);
          setVariationProfiles(prev => ({ ...prev, [file.name]: variationProfile }));
        };
      });
    } else {
      setError('You can upload up to 20 files at a time.');
      toast.error('You can upload up to 20 files at a time.');
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/jpeg, image/png, image/webp',
    maxFiles: 20,
    onDropRejected: () => {
      setError('You can upload up to 20 files at a time.');
      toast.error('You can upload up to 20 files at a time.');
    }
  });

  const handleUpload = async () => {
    const formData = new FormData();
    files.forEach(file => formData.append('files', file));

    try {
      setUploading(true);
      setError(null);
      setMessage(null);

      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.post('https://etsyhelper.onrender.com/products', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      if (response.data.message === 'Products uploaded successfully') {
        setMessage('Products uploaded successfully');
        toast.success('Products uploaded successfully');

        // Yönlendirmeden önce kısa bir gecikme ekleyin
        setTimeout(() => {
          navigate('/all-products');
        }, 3000); // 3 saniye gecikme
      } else {
        // Backend'den gelen mesajı yakalayın
        const errorMessage = response.data.message || 'Error uploading products';
        setError(errorMessage);
        toast.error(errorMessage);
        console.error('Error uploading products:', response.data);
      }
    } catch (error) {
      // Hata mesajını kontrol edin ve gösterin
      if (error.response && error.response.data && error.response.data.message) {
        const errorMessage = error.response.data.message;
        setError(errorMessage);
        toast.error(errorMessage); // Hata mesajını göster
      } else {
        setError('Error uploading files');
        toast.error('Error uploading files');
        console.error('Error uploading files:', error);
      }
    } finally {
      setUploading(false);
    }
};

  const removeFile = (file) => {
    setFiles(files.filter(f => f !== file));
    setAspectRatios(prev => {
      const updated = { ...prev };
      delete updated[file.name];
      return updated;
    });
    setVariationProfiles(prev => {
      const updated = { ...prev };
      delete updated[file.name];
      return updated;
    });
  };

  return (
    <div className="container mx-auto mt-10">
      <h1 className="text-4xl font-bold text-center mb-10">Bulk Image Upload</h1>
      <div className="flex justify-center mb-6">
        <div
          {...getRootProps({ className: `dropzone w-full max-w-lg p-6 border-2 border-dashed rounded-lg bg-white text-center cursor-pointer ${isDragActive ? 'border-purple-500' : 'border-gray-300'}` })}
        >
          <input {...getInputProps()} />
          <p className="text-gray-500">
            {isDragActive ? "Drop the files here..." : "Drag 'n' drop some files here, or click to select files (up to 20)"}
          </p>
        </div>
      </div>
      <div className="flex justify-center mb-8">
        <button
          onClick={handleUpload}
          className={`px-6 py-2 text-white rounded ${files.length && !uploading ? 'bg-purple-500 hover:bg-purple-600' : 'bg-gray-400 cursor-not-allowed'}`}
          disabled={!files.length || uploading}
        >
          {uploading ? 'Uploading...' : 'Upload Images'}
        </button>
      </div>
      {error && <div className="text-red-500 text-center mb-4">{error}</div>}
      {message && <div className="text-green-500 text-center mb-4">{message}</div>}
      <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
        {files.map((file, index) => (
          <div key={index} className="bg-white p-4 rounded-lg shadow-md">
            <img src={URL.createObjectURL(file)} alt={`preview-${index}`} className="w-full h-48 object-contain rounded-lg mb-4" />
            <p className="text-sm text-gray-500 mb-2">Varyasyon Profil: {variationProfiles[file.name]}</p>
            <button
              onClick={() => removeFile(file)}
              className="bg-red-500 text-white px-2 py-1 rounded"
            >
              Kaldır
            </button>
          </div>
        ))}
      </div>
      <ToastContainer />
    </div>
  );
}

export default BulkUpload;
