import React from 'react';
import { NavLink } from 'react-router-dom';
import { FaHome, FaProductHunt, FaHeadset, FaUserCog, FaUser, FaCog, FaStore, FaBoxes, FaFileAlt } from 'react-icons/fa';

function Sidebar() {
  return (
    <div className="bg-[#e5e3e4] h-screen p-5">
      <div className="mb-6">
        <h1 className="text-2xl font-bold text-[#282727]">Canvas Helper</h1>
      </div>
      <nav>
        <NavLink to="/" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
          <FaHome className="inline-block mr-2" /> Dashboard
        </NavLink>
        <div className="mt-4">
          <h2 className="text-lg font-semibold text-[#282727]">Ürünler</h2>
          <NavLink to="/all-products" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
            <FaProductHunt className="inline-block mr-2" /> Ürünler
          </NavLink>
          <NavLink to="/bulk-upload" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
            <FaProductHunt className="inline-block mr-2" /> Toplu Ürün Ekle
          </NavLink>
          <NavLink to="/variation-profiles" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
            <FaProductHunt className="inline-block mr-2" /> Varyasyon Profilleri
          </NavLink>
        </div>
        <div className="mt-4">
          <NavLink to="/support" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
            <FaHeadset className="inline-block mr-2" /> Support
          </NavLink>
        </div>
        <div className="mt-4">
          <h2 className="text-lg font-semibold text-[#282727]">Account Settings</h2>
          <NavLink to="/my-account/account-details" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
            <FaUser className="inline-block mr-2" /> Hesap Detayları
          </NavLink>
          <NavLink to="/my-account/subscription" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
            <FaCog className="inline-block mr-2" /> Abonelik Yönetimi
          </NavLink>
          <NavLink to="/my-account/description-generator" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
            <FaFileAlt className="inline-block mr-2" /> Description Generator
          </NavLink>
          <NavLink to="/my-account/stores" className={({ isActive }) => isActive ? 'active-link' : 'link'}>
            <FaStore className="inline-block mr-2" /> Mağazalarım
          </NavLink>
        </div>
      </nav>
    </div>
  );
}

export default Sidebar;
