import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AccountDetails from './AccountDetails';
import Stores from './Stores';
import Tabs from '../../components/Tabs';
import DescriptionGenerator from './DescriptionGenerator';
import Subscription from './Subscription';

const MyAccount = () => {
  const tabs = [
    { name: 'account-details', label: 'Account Details', path: '/my-account/account-details' },
    { name: 'stores', label: 'Stores', path: '/my-account/stores' },
    { name: 'Description Generator', label: 'Description Generator', path: '/my-account/description-generator' },
    { name: 'Subscription', label: 'Abonelik Yönetimi', path: '/my-account/subscription' },
  ];

  return (
    <div className="container mx-auto mt-10">
      <Tabs tabs={tabs} />
      <div className="mt-4">
        <Routes>
          <Route path="account-details" element={<AccountDetails />} />
          <Route path="stores" element={<Stores />} />
          <Route path="description-generator" element={<DescriptionGenerator />} />
          <Route path="subscription" element={<Subscription />} />
        </Routes>
      </div>
    </div>
  );
};

export default MyAccount;
