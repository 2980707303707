import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SubscriptionPage() {
  const [stores, setStores] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalAmountDue, setTotalAmountDue] = useState(null); // Başlangıç değeri null yapalım
  const [packages, setPackages] = useState([]);
  const [currentPackage, setCurrentPackage] = useState(null);
  const [billings, setBillings] = useState([]);
  const [loading, setLoading] = useState(true);

  // totalAmountDue state'i değiştiğinde konsola yazdırma
  useEffect(() => {
    console.log("Frontend totalAmountDue:", totalAmountDue);
    console.log(typeof(totalAmountDue))
  }, [totalAmountDue]);

  useEffect(() => {
    fetchData();
    fetchBillingTotal(); // Bu ayki ödenecek tutarı alıyoruz
  }, []);

  // Genel verileri al
  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');

      // Mağaza verilerini al
      const storesResponse = await axios.get('https://etsyhelper.onrender.com/stores/list', {
        headers: { Authorization: `Bearer ${token}` },
      });
      const storesData = storesResponse.data;
      setStores(storesData);

      // Toplam ürün sayısını hesapla
      const totalProductsCount = storesData.reduce((sum, store) => sum + store.product_count, 0);
      setTotalProducts(totalProductsCount);

      // Abonelik paketlerini al
      const packagesResponse = await axios.get('https://etsyhelper.onrender.com/subscription/packages', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setPackages(packagesResponse.data);

      // Kullanıcının mevcut aboneliğini al
      const currentPackageResponse = await axios.get('https://etsyhelper.onrender.com/subscription/current', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setCurrentPackage(currentPackageResponse.data);

      // Fatura verilerini al
      const billingResponse = await axios.get('https://etsyhelper.onrender.com/billing', {
        headers: { Authorization: `Bearer ${token}` },
      });
      setBillings(billingResponse.data);
    } catch (error) {
      console.error('Error fetching data:', error);
      toast.error('Abonelik verileri alınamadı.');
    } finally {
      setLoading(false); // Veriler alındıktan sonra loading'i kapat
    }
  };

  // Bu ayki toplam ödenecek tutarı almak için backend API çağrısı
  const fetchBillingTotal = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get('https://etsyhelper.onrender.com/subscription/billing_total', {
        headers: { Authorization: `Bearer ${token}` },
      });

      const totalAmount = response.data.total_amount; // Gelen veriyi al
      setTotalAmountDue(parseFloat(totalAmount)); // State'e set et
    } catch (error) {
      console.error('Error fetching billing total:', error);
      toast.error('Toplam fatura bilgisi alınamadı.');
    }
  };

  // Abonelik yükseltme veya düşürme işlemi
  const handleUpgrade = async (packageId) => {
    try {
      const token = localStorage.getItem('token');

      // API isteği yaparak paketi yükseltme veya düşürme işlemi gerçekleştir
      const response = await axios.post('https://etsyhelper.onrender.com/subscription/update_subscription', 
        { package_id: packageId },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      if (response.status === 200) {
        const message = response.data.message;
        toast.success(message); // Başarı mesajını göster

        // Verileri tekrar yükle (Mevcut paket vs. bilgileri güncellemek için)
        fetchData();
      }
    } catch (error) {
      // Hata mesajlarını kontrol et ve uygun hata mesajını göster
      if (error.response && error.response.data.message) {
        toast.error(error.response.data.message); // Hata mesajını göster
      } else {
        console.error('Error updating subscription:', error);
        toast.error('Abonelik güncellenemedi.');
      }
    }
  };

  return (
    <div className="container mx-auto mt-10">
      {/* İlk Bölüm: Mağazalar, Toplam Ürün Sayısı, Bu Ayki Ödenecek Tutar */}
      <div className="grid grid-cols-3 gap-4 mb-6">
        <div className="p-4 bg-white rounded shadow-md">
          <h2 className="text-xl font-bold">Mağazalar</h2>
          <p className="text-3xl">{stores.length}</p>
        </div>
        <div className="p-4 bg-white rounded shadow-md">
          <h2 className="text-xl font-bold">Toplam Ürün Sayısı</h2>
          <p className="text-3xl">{totalProducts}</p>
        </div>
        <div className="p-4 bg-white rounded shadow-md">
          <h2 className="text-xl font-bold">Bu Ayki Ödenecek Tutar</h2>
          <p className="text-3xl">
            {totalAmountDue !== null && typeof totalAmountDue === 'number' && !isNaN(totalAmountDue)
              ? `$${totalAmountDue.toFixed(2)}`
              : 'Yükleniyor...'} {/* Değer yüklenirken 'Yükleniyor...' göster */}
          </p>
        </div>
      </div>

      {/* İkinci Bölüm: Mağazalarınız */}
      <h1 className="text-3xl font-bold text-center mb-10">Mağazalarınız</h1>
      {loading ? (
        <p>Mağazalar yükleniyor...</p>
      ) : (
        <table className="min-w-full bg-white mb-10">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Mağaza Adı</th>
              <th className="py-2 px-4 border-b">Eklenme Tarihi</th>
              <th className="py-2 px-4 border-b">Ürün Sayısı</th>
            </tr>
          </thead>
          <tbody>
            {stores.map((store) => (
              <tr key={store.id}>
                <td className="py-2 px-4 border-b">{store.shop_name}</td>
                <td className="py-2 px-4 border-b">{new Date(store.created_at).toLocaleDateString()}</td>
                <td className="py-2 px-4 border-b">{store.product_count}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Üçüncü Bölüm: Abonelik Paketleri */}
      <h2 className="text-3xl font-bold text-center mb-6">Abonelik Paketleri</h2>
      <div className="grid grid-cols-3 gap-4">
        {packages.map((pkg) => (
          <div key={pkg.id} className={`p-4 rounded shadow-md ${currentPackage && currentPackage.id === pkg.id ? 'border-2 border-blue-500' : 'border'}`}>
            <h3 className="text-xl font-bold mb-2">{pkg.name}</h3>
            <p className="text-sm">Mağaza Limiti: {pkg.store_limit}</p>
            <p className="text-sm">Ürün Limiti: {pkg.product_limit}</p>
            <p className="text-sm font-bold">Fiyat: ${pkg.price}</p>
            {currentPackage && currentPackage.id !== pkg.id && (
              <button 
                onClick={() => handleUpgrade(pkg.id)} 
                className="mt-4 bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600"
              >
                Paketi Yükselt
              </button>
            )}
          </div>
        ))}
      </div>

      {/* Dördüncü Bölüm: Faturalar */}
      <h2 className="text-2xl font-bold text-center mb-6">Faturalarınız</h2>
      {loading ? (
        <p>Faturalar yükleniyor...</p>
      ) : billings.length === 0 ? (
        <p>Fatura kaydı bulunamadı.</p>
      ) : (
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Abonelik Paketi</th>
              <th className="py-2 px-4 border-b">Tutar</th>
              <th className="py-2 px-4 border-b">Fatura Tarihi</th>
              <th className="py-2 px-4 border-b">Yenilenme Tarihi</th>
            </tr>
          </thead>
          <tbody>
            {billings.map((bill, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{bill.package_name}</td>
                <td className="py-2 px-4 border-b">${bill.amount}</td>
                <td className="py-2 px-4 border-b">{new Date(bill.billing_date).toLocaleDateString()}</td>
                <td className="py-2 px-4 border-b">{new Date(bill.renewal_date).toLocaleDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}

      {/* Toast Container */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default SubscriptionPage;
