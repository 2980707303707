import React from 'react';
import { NavLink } from 'react-router-dom';

const Tabs = ({ tabs }) => {
  return (
    <div>
      <div className="flex border-b">
        {tabs.map(tab => (
          <NavLink
            key={tab.name}
            to={tab.path}
            className={({ isActive }) => 
              `py-2 px-4 text-sm font-medium ${isActive ? 'border-purple-500 text-purple-600' : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'}`
            }
          >
            {tab.label}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
