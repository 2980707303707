import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useAuth } from '../context/AuthContext';
import { toast } from 'react-toastify';
import { EditText } from 'react-edit-text';
import 'react-edit-text/dist/index.css';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import StaticImageUpload from './StaticImageUpload';

const ItemType = 'MOCKUP_SET';

const DraggableMockupSet = ({ set, index, moveMockupSet, handleSaveOffsets, handleDeleteSet }) => {
  const [, ref] = useDrag({
    type: ItemType,
    item: { index }
  });

  const [, drop] = useDrop({
    accept: ItemType,
    hover: (draggedItem) => {
      if (draggedItem.index !== index) {
        moveMockupSet(draggedItem.index, index);
        draggedItem.index = index;
      }
    }
  });

  return (
    <li ref={(node) => ref(drop(node))} className="bg-white p-4 rounded-lg shadow-md mb-4">
      <div className="flex justify-between items-center">
        <div className="flex">
          <div className="mr-4">
            <h4 className="text-lg font-semibold">Room Image</h4>
            <img src={set.room_url} alt={`room-${set.id}`} className="w-20 h-20 object-contain rounded" />
          </div>
          {set.frame_url && (
            <div className="mr-4">
              <h4 className="text-lg font-semibold">Frame Image</h4>
              <img src={set.frame_url} alt={`frame-${set.id}`} className="w-20 h-20 object-contain rounded" />
            </div>
          )}
          <div className="flex flex-col items-start ml-4">
            <div className="flex items-center mt-2">
              <label className="block text-sm font-medium text-gray-700 mr-2">Sol Boşluk: </label>
              <div className="relative">
                <EditText
                  name="left_offset"
                  defaultValue={set.left_offset}
                  onSave={({ value }) => handleSaveOffsets(set.id, 'left_offset', value)}
                  className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"></span>
              </div>
            </div>
            <div className="flex items-center mt-2">
              <label className="block text-sm font-medium text-gray-700 mr-2">Üst Boşluk: </label>
              <div className="relative">
                <EditText
                  name="top_offset"
                  defaultValue={set.top_offset}
                  onSave={({ value }) => handleSaveOffsets(set.id, 'top_offset', value)}
                  className="block w-full pl-3 pr-10 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <span className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-500"></span>
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => handleDeleteSet(set.id)}
          className="bg-red-500 text-white px-4 py-2 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600 mt-4"
        >
          Sil
        </button>
      </div>
    </li>
  );
};

const ImagesTab = ({ profileId }) => {
  const { user } = useAuth();
  const [roomImages, setRoomImages] = useState([]);
  const [frameImages, setFrameImages] = useState([]);
  const [mockupSets, setMockupSets] = useState([]);
  const [leftOffset, setLeftOffset] = useState('');
  const [topOffset, setTopOffset] = useState('');
  const [noFrame, setNoFrame] = useState(false); // Yeni durum
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchMockupSets = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`https://etsyhelper.onrender.com/profile_images?profile_id=${profileId}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setMockupSets(response.data);
      } catch (error) {
        console.error('Error fetching mockup sets:', error);
        setError('Failed to fetch mockup sets');
      }
    };

    fetchMockupSets();
  }, [profileId]);

  const onDropRoom = (acceptedFiles) => {
    const newRooms = [...roomImages, ...acceptedFiles].slice(0, 1);
    setRoomImages(newRooms);
  };

  const onDropFrame = (acceptedFiles) => {
    const newFrames = [...frameImages, ...acceptedFiles].slice(0, 1);
    setFrameImages(newFrames);
  };

  const { getRootProps: getRootPropsRoom, getInputProps: getInputPropsRoom } = useDropzone({
    onDrop: onDropRoom,
    accept: 'image/*',
    maxFiles: 1,
  });

  const { getRootProps: getRootPropsFrame, getInputProps: getInputPropsFrame } = useDropzone({
    onDrop: onDropFrame,
    accept: 'image/*',
    maxFiles: 1,
    disabled: noFrame, // noFrame seçiliyse çerçeve alanını devre dışı bırak
  });

  const handleSaveSet = async () => {
    if (roomImages.length === 0) {
      toast.error('Room image is required');
      return;
    }

    if (!noFrame && frameImages.length === 0) {
      toast.error('Frame image is required');
      return;
    }

    if (!leftOffset || !topOffset) {
      toast.error('Both left and top offsets are required');
      return;
    }

    const formData = new FormData();
    formData.append('profile_id', profileId);
    formData.append('room_image', roomImages[0]);
    if (!noFrame) {
      formData.append('frame_image', frameImages[0]);
    }
    formData.append('left_offset', leftOffset);
    formData.append('top_offset', topOffset);
    formData.append('no_frame', noFrame);

    try {
      const token = localStorage.getItem('token');
      await axios.post('https://etsyhelper.onrender.com/profile_images', formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      toast.success('Mockup set saved successfully');
      setRoomImages([]);
      setFrameImages([]);
      setLeftOffset('');
      setTopOffset('');
      setNoFrame(false);
      fetchMockupSets();
    } catch (error) {
      console.error('Error saving mockup set:', error);
      toast.error('Failed to save mockup set');
    }
  };

  const fetchMockupSets = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`https://etsyhelper.onrender.com/profile_images?profile_id=${profileId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMockupSets(response.data);
    } catch (error) {
      console.error('Error fetching mockup sets:', error);
      setError('Failed to fetch mockup sets');
    }
  };

  const handleDeleteSet = async (setId) => {
    try {
      const token = localStorage.getItem('token');
      await axios.delete(`https://etsyhelper.onrender.com/profile_images/${setId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
      setMockupSets(mockupSets.filter(set => set.id !== setId));
      toast.success('Mockup set deleted successfully');
    } catch (error) {
      console.error('Error deleting mockup set:', error);
      toast.error('Failed to delete mockup set');
    }
  };

  const handleSaveOffsets = async (setId, name, value) => {
    const updatedMockupSets = mockupSets.map((set) => {
      if (set.id === setId) {
        return { ...set, [name]: value };
      }
      return set;
    });
    setMockupSets(updatedMockupSets);

    try {
      const token = localStorage.getItem('token');
      await axios.put(`https://etsyhelper.onrender.com/profile_images/${setId}`, {
        left_offset: name === 'left_offset' ? value : updatedMockupSets.find(set => set.id === setId).left_offset,
        top_offset: name === 'top_offset' ? value : updatedMockupSets.find(set => set.id === setId).top_offset,
      }, {
        headers: { Authorization: `Bearer ${token}` },
      });
      toast.success('Mockup set updated successfully');
    } catch (error) {
      console.error('Error updating mockup set:', error);
      toast.error('Failed to update mockup set');
    }
  };

  const moveMockupSet = (dragIndex, hoverIndex) => {
    const draggedMockupSet = mockupSets[dragIndex];
    const updatedSets = update(mockupSets, {
      $splice: [
        [dragIndex, 1],
        [hoverIndex, 0, draggedMockupSet]
      ]
    });

    // Update the list numbers based on the new order
    const reorderedSets = updatedSets.map((set, idx) => ({
      ...set,
      list_number: idx
    }));

    setMockupSets(reorderedSets);

    // Save the new order to the database
    const token = localStorage.getItem('token');
    axios.put('https://etsyhelper.onrender.com/profile_images/reorder', reorderedSets.map(set => ({ id: set.id, list_number: set.list_number })), {
      headers: { Authorization: `Bearer ${token}` }
    })
    .then(() => toast.success('Order saved successfully'))
    .catch(error => {
      console.error('Error saving order:', error);
      toast.error('Failed to save order');
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div>
        {error && <p className="text-red-500">{error}</p>}
        
        <div className="grid grid-cols-2 gap-4">
          <div>
            <h3 className="text-xl font-semibold">Oda Resmi</h3>
            <div {...getRootPropsRoom({ className: 'dropzone' })}>
              <input {...getInputPropsRoom()} />
              <p>Bir oda resmini buraya sürükleyip bırakın veya seçmek için tıklayın</p>
            </div>
            <div className="grid grid-cols-1 gap-2 mt-4">
              {roomImages.map((file, index) => (
                <div key={index} className="relative">
                  <img src={URL.createObjectURL(file)} alt={`room-${index}`} className="w-20 h-20 object-contain rounded" />
                  <button
                    onClick={() => setRoomImages([])}
                    className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                  >
                    &times;
                  </button>
                </div>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-xl font-semibold">Çerçeve Resmi</h3>
            {!noFrame && (
              <>
                <div {...getRootPropsFrame({ className: 'dropzone' })}>
                  <input {...getInputPropsFrame()} />
                  <p>Bir çerçeve resmini buraya sürükleyin veya seçmek için tıklayın</p>
                </div>
                <div className="grid grid-cols-1 gap-2 mt-4">
                  {frameImages.map((file, index) => (
                    <div key={index} className="relative">
                      <img src={URL.createObjectURL(file)} alt={`frame-${index}`} className="w-20 h-20 object-contain rounded" />
                      <button
                        onClick={() => setFrameImages([])}
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                      >
                        &times;
                      </button>
                    </div>
                  ))}
                </div>
              </>
            )}
            <div className="flex items-center mt-4">
              <input
              type="checkbox"
              id="no-frame"
              checked={noFrame}
              onChange={(e) => setNoFrame(e.target.checked)}
              className="mr-2"
            />
            <label htmlFor="no-frame" className="text-sm font-medium text-gray-700">
              No Frame
            </label>
          </div>
        </div>
      </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Sol Boşluk
          </label>
          <input
            type="number"
            value={leftOffset}
            onChange={(e) => setLeftOffset(e.target.value)}
            className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <div className="mt-4">
          <label className="block text-sm font-medium text-gray-700">
            Üst Boşluk
          </label>
          <input
            type="number"
            value={topOffset}
            onChange={(e) => setTopOffset(e.target.value)}
            className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          />
        </div>

        <button
          onClick={handleSaveSet}
          className="mt-4 bg-purple-500 text-white py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
        >
          Seti Kaydet
        </button>
        
        <div className="mt-8">
          <h3 className="text-xl font-semibold">Mockup Sets</h3>
          <ul className="list-disc pl-5">
            {mockupSets.map((set, index) => (
              <DraggableMockupSet
                key={set.id}
                index={index}
                set={set}
                moveMockupSet={moveMockupSet}
                handleSaveOffsets={handleSaveOffsets}
                handleDeleteSet={handleDeleteSet}
              />
            ))}
          </ul>
        </div>
        <div className="container mx-auto mt-10">
          <h1 className="text-3xl font-bold mb-6">Varyasyon Profili İçin Sabit Resimler</h1>

          {/* StaticImageUpload Bileşeni */}
          <StaticImageUpload profileId={profileId} fetchStaticImages={() => { /* Resim güncelleme mantığı */ }} />
        </div>
      </div>
    </DndProvider>
  );
};

export default ImagesTab;
