import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { FaUserCircle } from 'react-icons/fa';
import { useAuth } from '../context/AuthContext';
import axios from 'axios';

Modal.setAppElement('#root');

function Header() {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);
  const [stores, setStores] = useState([]);
  const [selectedStore, setSelectedStore] = useState(null);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      fetchStores();
    }
  }, [isAuthenticated]);

  const fetchStores = async () => {
    try {
      const token = localStorage.getItem('token');
      const storesResponse = await axios.get('https://etsyhelper.onrender.com/stores/list', {
        headers: { Authorization: `Bearer ${token}` },
      });

      setStores(storesResponse.data);

      if (storesResponse.data.length > 0) {
        const selectedStoreResponse = await axios.get('https://etsyhelper.onrender.com/stores/get-selected-store', {
          headers: { Authorization: `Bearer ${token}` },
        });

        const lastSelectedStoreId = selectedStoreResponse.data.last_selected_store_id;
        if (lastSelectedStoreId) {
          setSelectedStore(lastSelectedStoreId);
        } else {
          setSelectedStore(storesResponse.data[0].id);
        }
      } else {
        setShowModal(true);
      }
    } catch (error) {
      console.error('Error fetching stores:', error);
    }
  };

  const handleStoreChange = async (storeId) => {
    setSelectedStore(storeId);

    try {
      const token = localStorage.getItem('token');
      await axios.post('https://etsyhelper.onrender.com/stores/select-store', { store_id: storeId }, {
        headers: { Authorization: `Bearer ${token}` },
      });

      console.log(`Selected store ID: ${storeId} saved successfully.`);
      window.location.reload();
    } catch (error) {
      console.error('Error saving selected store:', error);
    }
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const navigateToStores = () => {
    navigate('/my-account/stores');
    setShowModal(false);
  };

  return (
    <header className="bg-[#e5e3e4] p-4 shadow-md">
      <div className="container mx-auto flex justify-between items-center">
        <div className="relative">
          {isAuthenticated && stores.length > 0 ? (
            <select
              value={selectedStore || ""}
              onChange={(e) => handleStoreChange(e.target.value)}
              className="p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            >
              {stores.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.shop_name}
                </option>
              ))}
            </select>
          ) : (
            <button 
              onClick={navigateToStores}
              className="text-[#282727] text-xl font-bold underline"
            >
              Mağaza Seçimi Yapın
            </button>
          )}
        </div>
        <nav className="flex items-center">
          {isAuthenticated ? (
            <div className="relative">
              <div className="flex items-center cursor-pointer" onClick={toggleMenu}>
                <FaUserCircle className="text-2xl text-[#282727]" />
                <span className="ml-2 text-[#282727]">Hesabım</span>
              </div>
              {menuOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-lg p-2">
                  <Link to="/my-account/account-details" className="block px-4 py-2 text-[#282727] hover:bg-gray-200" onClick={() => setMenuOpen(false)}>Hesap Detayları</Link>
                  <Link to="/my-account/subscription" className="block px-4 py-2 text-[#282727] hover:bg-gray-200" onClick={() => setMenuOpen(false)}>Abonelik Yönetimi</Link>
                  <Link to="/my-account/stores" className="block px-4 py-2 text-[#282727] hover:bg-gray-200" onClick={() => setMenuOpen(false)}>Mağazalarım</Link>
                  <button onClick={handleLogout} className="block w-full text-left px-4 py-2 text-[#282727] hover:bg-gray-200">Oturumu Kapat</button>
                </div>
              )}
            </div>
          ) : (
            <>
              <Link to="/login" className="text-[#282727] mr-4">Login</Link>
              <Link to="/register" className="text-[#282727]">Register</Link>
            </>
          )}
        </nav>
      </div>

      {/* Popup Modal */}
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Mağaza Ekleme"
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg mx-auto my-16"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 z-50"
      >
        <h2 className="text-2xl font-bold mb-4">Mağaza Ekleme</h2>
        <p className="mb-6">Ekli bir mağazanız yok. Lütfen bir mağaza ekleyin.</p>
        <button
          onClick={navigateToStores}
          className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
        >
          Mağaza Ekle
        </button>
      </Modal>
    </header>
  );
}

export default Header;
