import React from 'react';

function PricesTab({ profile, priceInput, handlePriceChange, handleSavePrices }) {
  return (
    <div>
      <h3 className="text-xl font-semibold">Prices</h3>
      <ul className="divide-y divide-gray-200">
        {profile.dimensions?.map(dimension => (
          profile.frames?.map(frame => (
            <li key={`${dimension.id}-${frame.id}`} className="py-4 flex justify-between items-center">
              <div className="flex items-center">
                <p className="mr-4">{dimension.dimension}</p>
                <p>{frame.frame}</p>
              </div>
              <div className="flex items-center">
                <input
                  type="number"
                  value={priceInput[`${dimension.id}-${frame.id}`] || ''}
                  onChange={(e) => handlePriceChange(dimension.id, frame.id, e.target.value)}
                  placeholder="Add price"
                  className="mr-2 px-2 py-1 border border-gray-300 rounded-md"
                />
                {profile.prices?.filter(p => p.dimensionId === dimension.id && p.frameId === frame.id).map(p => (
                  <div key={p.id} className="ml-4">
                    <p>{p.price}</p>
                  </div>
                ))}
              </div>
            </li>
          ))
        ))}
      </ul>
      <button
        onClick={() => handleSavePrices(profile.id)}
        className="mt-4 bg-purple-500 text-white py-2 px-4 rounded-md hover:bg-purple-600 focus:outline-none focus:bg-purple-600"
      >
        Save Prices
      </button>
    </div>
  );
}

export default PricesTab;
