import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';

function Stores({ setSelectedStore }) {
    const [stores, setStores] = useState([]);
    const [name, setName] = useState('');
    const [etsyShopId, setEtsyShopId] = useState('');
    const [loading, setLoading] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [storeToDelete, setStoreToDelete] = useState(null);
    const [limitExceeded, setLimitExceeded] = useState(false); // Yeni state
    const navigate = useNavigate();  // useHistory yerine useNavigate kullanıyoruz

    useEffect(() => {
        fetchStores();
    }, []);

    const fetchStores = async () => {
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            const response = await axios.get('https://etsyhelper.onrender.com/stores/list', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setStores(response.data);
            if (response.data.length > 0) {
                setSelectedStore(response.data[0].id); // İlk mağazayı seçili olarak ayarla
            }
        } catch (error) {
            console.error('Error fetching stores:', error);
            toast.error('Failed to fetch stores.');
        } finally {
            setLoading(false);
        }
    };


    const handleAuthorize = async () => {
        const state = Math.random().toString(36).substring(7);  // Rastgele bir state oluşturun
        const clientId = process.env.REACT_APP_ETSY_CLIENT_ID;
        const redirectUri = process.env.REACT_APP_ETSY_REDIRECT_URI;
        const authUrl = `https://www.etsy.com/oauth/connect?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=transactions_r&state=${state}`;
    
        localStorage.setItem('oauthState', state);  // State değerini saklayın
        window.location.href = authUrl;
    };
    
    
    
    
    
    const handleStoreSubmit = async (e) => {
        e.preventDefault();
        try {
            const token = localStorage.getItem('token');
            const storeData = {
                name,
                etsy_shop_id: null  // Shop ID henüz belirlenmiyor, yetkilendirme sonrası eklenecek
            };
    
            const response = await axios.post('https://etsyhelper.onrender.com/stores/create', storeData, {
                headers: { Authorization: `Bearer ${token}` },
            });
    
            if (response.data.store_id) {
                toast.success('Mağaza başarıyla oluşturuldu!');
                fetchStores();  // Mağaza oluşturulduktan sonra mağazalar yeniden yükleniyor
                setSelectedStore(response.data.store_id);
                setName('');
                setEtsyShopId('');
                handleAuthorize();  // Mağaza oluşturulduktan sonra yetkilendirme süreci başlatılıyor
            }
        } catch (error) {
            if (error.response && error.response.status === 403) {
                setLimitExceeded(true); // Mağaza limiti aşılmışsa kullanıcıya bildirilir
                setShowModal(true);
            } else {
                console.error('Mağaza oluşturulurken hata oluştu:', error);
                toast.error('Mağaza oluşturulamadı.');
            }
        }
    };
    
    const handleReauthorize = async (storeId) => {
        try {
            const clientId = process.env.REACT_APP_ETSY_CLIENT_ID;
            const redirectUri = process.env.REACT_APP_ETSY_REDIRECT_URI;
            const authUrl = `https://www.etsy.com/oauth/connect?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=transactions_r`;
    
            window.location.href = authUrl;  // Kullanıcıyı yetkilendirme sayfasına yönlendiriyoruz
        } catch (error) {
            console.error('Yetkilendirme URL\'si oluşturulamadı:', error);
            toast.error('Yetkilendirme URL\'si oluşturulamadı.');
        }
    };
    
    

    const confirmRemove = (storeId) => {
        setStoreToDelete(storeId);
        setShowModal(true);
    };

    const handleRemove = async () => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`https://etsyhelper.onrender.com/stores/delete/${storeToDelete}`, {
                headers: { Authorization: `Bearer ${token}` },
            });

            toast.success('Store removed successfully!');
            fetchStores(); // Mağaza silindikten sonra mağazaları yeniden yükle
            setShowModal(false); // Modalı kapat
            setStoreToDelete(null); // Silinecek mağazayı sıfırla
        } catch (error) {
            console.error('Error removing store:', error);
            toast.error('Failed to remove store.');
            setShowModal(false); // Modalı kapat
            setStoreToDelete(null);
        }
    };

    const handleUpgradePlan = () => {
        navigate('/my-account/subscription');
        setShowModal(false); // Modalı kapat
    };

    return (
    <div className="container mx-auto mt-10">
        <h1 className="text-3xl font-bold text-center mb-10">Manage Your Stores</h1>

        <form onSubmit={handleStoreSubmit} className="mb-8">
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    Store Name
                </label>
                <input 
                    type="text" 
                    value={name} 
                    onChange={(e) => setName(e.target.value)} 
                    required 
                    className="mt-1 block w-full pl-3 pr-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
            </div>
            <button 
                type="submit" 
                className="w-full bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600"
            >
                Add Store
            </button>
        </form>

        <h2 className="text-2xl font-bold mb-4">Your Stores</h2>
        {loading ? (
            <p>Loading stores...</p>
        ) : (
            <ul className="list-disc pl-5">
                {stores.length === 0 ? (
                    <p>No stores found.</p>
                ) : (
                    stores.map((store) => (
                        <li key={store.id} className="bg-white p-4 rounded-lg shadow-md mb-4">
                            <p className="text-lg font-semibold">{store.shop_name || "Store Name Pending"}</p>
                            <p className="text-sm text-gray-500">
                                {store.shop_id ? `Etsy Shop ID: ${store.shop_id}` : "Not Authorized Yet"}
                            </p>
                            <div className="mt-4 flex space-x-4">
                                <button 
                                    onClick={() => handleReauthorize(store.id)} 
                                    className="bg-yellow-500 text-white py-2 px-4 rounded-md hover:bg-yellow-600 focus:outline-none focus:bg-yellow-600"
                                >
                                    Authorize
                                </button>
                                <button 
                                    onClick={() => confirmRemove(store.id)} 
                                    className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 focus:outline-none focus:bg-red-600"
                                >
                                    Remove
                                </button>
                            </div>
                        </li>
                    ))
                )}
            </ul>
        )}

            {limitExceeded && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
                        <div className="bg-white rounded-lg shadow-xl z-50 p-6">
                            <h2 className="text-xl font-bold mb-4">Store Limit Reached</h2>
                            <p className="mb-4">You have reached the store limit for your current subscription package. Please upgrade your plan to add more stores.</p>
                            <div className="flex justify-end space-x-4">
                                <button 
                                    className="bg-blue-500 text-white py-2 px-4 rounded-md hover:bg-blue-600" 
                                    onClick={handleUpgradePlan}
                                >
                                    Upgrade Plan
                                </button>
                                <button 
                                    className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400" 
                                    onClick={() => setShowModal(false)}
                                >
                                    Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {showModal && storeToDelete && !limitExceeded && (
                <div className="fixed z-10 inset-0 overflow-y-auto">
                    <div className="flex items-center justify-center min-h-screen">
                        <div className="fixed inset-0 bg-gray-500 bg-opacity-75"></div>
                        <div className="bg-white rounded-lg shadow-xl z-50 p-6">
                            <h2 className="text-xl font-bold mb-4">Emin misiniz?</h2>
                            <p className="mb-4">Bu işlem mağazanızı, bağlı bulunan ürünleri ve tüm ilişkili verileri kalıcı olarak silecektir.</p>
                            <div className="flex justify-end space-x-4">
                                <button 
                                    className="bg-gray-300 text-gray-700 py-2 px-4 rounded-md hover:bg-gray-400" 
                                    onClick={() => setShowModal(false)}
                                >
                                    İptal
                                </button>
                                <button 
                                    className="bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600" 
                                    onClick={handleRemove}
                                >
                                    Mağazayı Kaldır
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Stores;